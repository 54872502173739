define("backalley/pods/components/order-edit/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R6YYWFAu",
    "block": "{\"symbols\":[\"HotLoadFaIconginzmt4nu3\",\"@reservations\"],\"statements\":[[7,\"p\",true],[10,\"class\",\"box-view-heading\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"tabindex\",\"-1\"],[3,\"action\",[[23,0,[]],\"toggleComment\",\"normal\"]],[8],[0,\"Add a note\"],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"tabindex\",\"-1\"],[3,\"action\",[[23,0,[]],\"toggleComment\",\"quick\"]],[8],[0,\"Quick note\"],[7,\"span\",true],[8],[0,\" / \"],[9],[9],[0,\"\\n  Notes\\n  \"],[7,\"div\",true],[10,\"class\",\"info-tooltip tooltip-box-view\"],[8],[0,\"\\n    \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"question-circle\"]]]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"e.g.: \\\"Refunded because...\\\", \\\"Waiting for customer to respond...\\\", etc.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"order-notes\",[23,0,[]],[24,[\"order-notes\"]],\"order-notes\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"item\",\"order\",\"mode\",\"expanded\",\"requesters\",\"products\",\"boutiques\",\"reservations\"],[[23,0,[]],\"order-notes\",[24,[\"order-notes\"]],false,true,[24,[\"order\",\"_id\"]],[24,[\"order\"]],[24,[\"_notesMode\"]],[24,[\"_expandedSection\"]],[24,[\"requesters\"]],[24,[\"products\"]],[24,[\"boutiques\"]],[23,2,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/notes/template.hbs"
    }
  });

  _exports.default = _default;
});