define("backalley/pods/main/tags/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ixzh566S",
    "block": "{\"symbols\":[\"HotLoadTagFormpj6r17b7f9q\",\"HotLoadPageHeading9755p6rpowm\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageHeading\",[23,0,[]],\"PageHeading\",\"PageHeading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageHeading\",\"PageHeading\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[],[[\"@fa\"],[\"tags\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.tags\"]],{\"statements\":[[0,\"\\n      Tags\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    New tag\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"New tag\"],null],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"TagForm\",[23,0,[]],\"TagForm\",\"TagForm\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"TagForm\",\"TagForm\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@tag\"],[[24,[\"model\",\"tag\"]]]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/tags/new/template.hbs"
    }
  });

  _exports.default = _default;
});