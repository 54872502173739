define("backalley/pods/main/orders/index/route", ["exports", "backalley/models/order", "backalley/mixins/request", "backalley/models/boutique", "backalley/util/escape-regex", "backalley/enums/channel", "backalley/services/country"], function (_exports, _order, _request, _boutique, _escapeRegex, _channel, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const possibleCountries = ['Australia', 'Austria', 'Belgium', 'Bulgaria', 'Canada', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hong Kong', 'Ireland', 'Italy', 'Japan', 'Luxembourg', 'Mexico', 'Netherlands', 'New Zealand', 'Norway', 'Poland', 'Polónia', 'Portugal', 'Romania', 'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'Taiwan', 'Thailand', 'United Arab Emirates', 'United Kingdom', 'United States', 'Vanuatu', 'Vietnam'];

  var _default = Ember.Route.extend(_request.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      name: {
        refreshModel: true
      },
      email: {
        refreshModel: true
      },
      boutique_q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      pending: {
        refreshModel: true
      },
      completed: {
        refreshModel: true
      },
      order_mode: {
        refreshModel: true
      },
      charge: {
        refreshModel: true
      },
      vip: {
        refreshModel: true
      },
      order_ref: {
        refreshModel: true
      },
      channel: {
        refreshModel: true
      },
      destination_country: {
        refreshModel: true
      }
    },

    serializeQueryParam(value = {}, urlKey = '') {
      // don't show objects in url
      if (urlKey === 'page') return Number(value);
      if (urlKey === 'boutique_q') return `${value.label}||${value.value}`;
      return value;
    },

    deserializeQueryParam(value = {}, urlKey = '') {
      if (urlKey === 'boutique_q') {
        const temp = value.split('||');
        return {
          label: temp[0],
          value: temp[1]
        };
      }

      return value; // ember bs magic, to serialize booleans to the url, do not remove
    },

    availableSort: [{
      label: 'Order Number: Descending',
      value: '-ordernumber'
    }, {
      label: 'Order Number: Ascending',
      value: 'ordernumber'
    }, {
      label: 'Amount in GBP: Highest value first',
      value: '-amount'
    }, {
      label: 'Amount in GBP: Lowest value first',
      value: 'amount'
    }, {
      label: 'Updated At: Newer first',
      value: '-updated_at'
    }, {
      label: 'Updated At: Older first',
      value: 'updated_at'
    }],
    availableStatus: [{
      label: 'Order Status: All',
      value: 'null'
    }, {
      label: 'Order Status: Active',
      value: 'active'
    }, {
      label: 'Order Status: Success',
      value: 'success'
    }, {
      label: 'Order Status: Failed',
      value: 'fail'
    }, {
      label: 'Order Status: Creating',
      value: 'creating'
    }],
    availableCompleted: [{
      label: 'Completed: All',
      value: 'null'
    }, {
      label: 'Completed: Yes',
      value: 'completed'
    }, {
      label: 'Completed: No',
      value: 'not completed'
    }],
    availableOrderModes: [{
      label: 'Mode: All',
      value: 'null'
    }, {
      label: 'Mode: Manual',
      value: 'manual'
    }, {
      label: 'Mode: Automatic',
      value: 'auto'
    }],
    availableChannels: [{
      label: 'Vertical: All',
      value: 'null'
    }, ...Object.values(_channel.default).filter(channel => channel !== _channel.default.MADE_DOT_COM).map(channel => ({
      label: `Vertical: ${Ember.String.capitalize(channel)}`,
      value: channel
    }))],
    availableCountries: possibleCountries.map(country => ({
      label: `Deliver To: ${country}`,
      value: country
    })),

    model({
      page,
      name,
      email,
      order_mode,
      boutique_q,
      sort,
      status,
      pending,
      completed,
      charge,
      vip,
      order_ref: orderRef,
      channel,
      destination_country
    }) {
      let limit = 20;
      let query = {
        limit: limit,
        sort: sort,
        page: Number(page),
        channel: {
          $ne: _channel.default.MADE_DOT_COM
        }
      };
      if (pending) query = _objectSpread(_objectSpread({}, query), {}, {
        pending: true
      });
      if (completed === 'completed') query = _objectSpread(_objectSpread({}, query), {}, {
        completed: true
      });
      if (completed === 'not completed') query = _objectSpread(_objectSpread({}, query), {}, {
        completed: false
      });
      if (charge) query = _objectSpread(_objectSpread({}, query), {}, {
        $or: [{
          outstanding_balance: {
            $gt: 2
          }
        }, {
          outstanding_balance: {
            $lt: -2
          }
        }]
      });
      if (status !== 'null') query = _objectSpread(_objectSpread({}, query), {}, {
        $and: [{
          status: status
        }]
      });
      if (status === 'success') query = _objectSpread(_objectSpread({}, query), {}, {
        $and: undefined,
        $or: [{
          status: 'success'
        }, {
          status: 'part success'
        }]
      });
      if (status === 'fail') query = _objectSpread(_objectSpread({}, query), {}, {
        $and: undefined,
        $or: [{
          status: 'fail'
        }, {
          status: 'fail payment'
        }]
      });
      if (vip) query = _objectSpread(_objectSpread({}, query), {}, {
        amount: {
          $gte: 20000
        }
      });
      if (order_mode === 'manual') query = _objectSpread(_objectSpread({}, query), {}, {
        manual_mode: true
      });
      if (order_mode === 'auto') query = _objectSpread(_objectSpread({}, query), {}, {
        manual_mode: false
      });

      if (email) {
        query = _objectSpread(_objectSpread({}, query), {}, {
          email: email.trim()
        });
      }

      if (name) {
        query = _objectSpread(_objectSpread({}, query), {}, {
          full_name: name.trim()
        });
      }

      if (orderRef) {
        query.ordernumber = orderRef;
      }

      if (channel !== 'null') query = _objectSpread(_objectSpread({}, query), {}, {
        channel
      });
      let prefilters = [];

      if (boutique_q) {
        // boutiques are referenced only in the reservations
        // so let's get the reservations for this boutique
        let promise = this.request({
          method: 'POST',
          url: this.apiURL('reservation_boutiques'),
          headers: {
            'X-HTTP-Method-Override': 'GET'
          },
          data: JSON.stringify({
            boutique: boutique_q.value,
            fields: 'order',
            sort: sort.indexOf('created_at') > -1 ? sort : '-created_at',
            plain: true
          })
        }).then(({
          reservation_boutiques
        }) => {
          if (reservation_boutiques.length < 1) return {
            ordernumber: 1
          }; // don't show any
          // then return a query to get the orders for these reservation_boutiques

          let ordersForBoutique = reservation_boutiques.map(({
            order
          }) => order) // uniq
          .filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => {
            return new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1;
          });
          return _objectSpread(_objectSpread({}, query), {}, {
            _id: {
              $in: ordersForBoutique
            }
          });
        });
        prefilters.push(promise);
      }

      if (typeof query.status === 'undefined') {
        query.status = '*';
      }

      if (typeof query.resource_state === 'undefined') {
        query.resource_state = '*';
      }

      return Ember.RSVP.all(prefilters).then(function (filters) {
        // merge queries
        return filters.reduce((prev, curr) => {
          return _objectSpread(_objectSpread({}, prev), curr);
        }, query);
      }).then(query => _order.default.find(query)).then(orders => {
        return Ember.RSVP.hash({
          orders: orders.orders,
          availableSort: this.availableSort,
          availableStatus: this.availableStatus,
          availablePending: this.availablePending,
          availableWeengs: this.availableWeengs,
          availableCompleted: this.availableCompleted,
          availableOrderModes: this.availableOrderModes,
          availableChannels: this.availableChannels,
          availableCountries: this.availableCountries,
          page,
          total: Math.ceil(orders.meta.total / limit),
          params: {
            page,
            email,
            name,
            boutique_q,
            sort,
            status,
            pending,
            completed,
            charge,
            vip,
            channel
          },
          sortValue: sort,
          statusValue: status,
          pendingValue: pending,
          completedValue: completed,
          orderModeValue: order_mode,
          channelValue: channel,
          destinationCountryValue: destination_country,
          updateControllerSelect: updateControllerSelect.bind(this),
          actions: {
            setOnController: setOnController.bind(this),
            searchBoutiques: searchBoutiques.bind(this),
            toggle: key => {
              return this.controller.toggleProperty(key);
            }
          }
        });
      });
    }

  });

  _exports.default = _default;

  function setOnController(key, value) {
    this.set(`controller.${key}`, value);
  }

  function searchBoutiques(q) {
    if (Ember.isEmpty(q)) return;
    const query = {
      name: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      limit: 10
    };
    return _boutique.default.find(query).then(boutiques => boutiques.map(({
      name,
      _id
    }) => ({
      label: name,
      value: _id
    }))).then(boutiques => this.set('currentModel.boutiques', boutiques));
  }

  function updateControllerSelect(key, value) {
    this.set(`controller.${key}`, value.value);
    this.set('controller.page', 1);
  }
});